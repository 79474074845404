import './style.scss';
import Modal from 'components/UI/Modal';
import Button from 'components/UI/Button';
import SelectBox from 'components/UI/SelectBox';
import Tether from 'assets/images/tether.png';
import Bitcoin from 'assets/images/bitcoin.png';
import { ReactComponent as InfoIcon } from 'assets/icons/exclamation.svg';

import { useState } from 'react';

const modalData = [
  { img: Tether, crypto: 'Tether' },
  { img: Bitcoin, crypto: 'Bitcoin' },
];

interface PropTypes {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function WithdrawalModal({
  modal,
  setModal,
}: PropTypes) {
  const [selectedPercent, setSelectedPercent] = useState<number>();

  return (
    <Modal
      setModal={setModal}
      title="Request Withdraw"
      show={modal}
    >
      <div className="modal-content">
        <div className="content">
          <div className="amount">
            <div>Your Amount</div>
            <div>
              <span>you can Get : </span>139.97$
            </div>
          </div>
          <div>
            <SelectBox data={modalData} />
          </div>

          <div className="percents">
            <div
              className={
                'percents__item ' +
                (selectedPercent === 25 ? 'selected-percent' : '')
              }
              onClick={() => setSelectedPercent(25)}
            >
              25%
            </div>
            <div
              className={
                'percents__item ' +
                (selectedPercent === 50 ? 'selected-percent' : '')
              }
              onClick={() => setSelectedPercent(50)}
            >
              50%
            </div>
            <div
              className={
                'percents__item ' +
                (selectedPercent === 75 ? 'selected-percent' : '')
              }
              onClick={() => setSelectedPercent(75)}
            >
              75%
            </div>
            <div
              className={
                'percents__item ' +
                (selectedPercent === 100 ? 'selected-percent' : '')
              }
              onClick={() => setSelectedPercent(100)}
            >
              100%
            </div>
          </div>
          <div className="alert">
            <InfoIcon />
            Expert mode turns off the 'Confirm' transaction prompt,
            and allows high slippage trades that often result in bad
            rates and lost funds
          </div>
        </div>
        <div className="button">
          <Button
            badge="001"
            title="Request Withdraw"
            variant="primary"
          />
        </div>
      </div>
    </Modal>
  );
}
