import './style.scss';
import NavItem from './NavItem';
import Profile from 'components/Shared/Profile';

interface NavbarProps {
  onToggle: () => void;
  isOpen: boolean;
}

export default function Navbar({ onToggle }: NavbarProps) {
  return (
    <div className="dnavbar">
      <div
        className='dnavbar__profile'
      >
        <div className="dnavbar__toggle" onClick={onToggle}>
          <span />
          <span />
          <span />
        </div>
        <Profile 
          userType="active" 
          name="Mason Franco" 
          avatar="https://randomuser.me/api/portraits/men/1.jpg"
          />
      </div>

      <NavItem />
    </div>
  );
}
