import './style.scss';
interface PropTypes {
  userType: string;
}
export default function Uploadfile({ userType }: PropTypes) {
  return (
    <div className="avatar">
      <h5 className="avatar__title">Upload Image</h5>
      <div className="avatar__content">
        <div
          className={`avatar__image avatar__image--${userType}`}
        >
          <img
            src="https://randomuser.me/api/portraits/men/1.jpg"
            className="avatar__image-preview"
            alt=""
          />
        </div>
        <div className="avatar__upload">
          <label className="avatar__label">
            <input
              type="file"
              required
              className="avatar__input"
            />
            Upload
          </label>
          <span className="avatar__desc">JPG, Gif, Webp, or PNG. Max size of 10MB</span>
        </div>
      </div>
    </div>
  );
}
