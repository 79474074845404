import './style.scss';
import BitcoinImage from 'assets/images/bitcoin.png';

interface PropTypes {
  image?: string;
  project?: string;
  detail?: string;
}

export default function Project({
  image,
  project,
  detail,
}: PropTypes) {
  return (
    <div className="project-container">
      <div className="project-image">
        <img src={`${BitcoinImage}`} />
      </div>
      <div>
        <div className="Project-name">{project}</div>
        <div className="detail">{detail}</div>
      </div>
    </div>
  );
}
