import './style.scss';
import { useState } from 'react';
import { ReactComponent as Arrow } from 'assets/icons/arrowdown.svg';

interface PropTypes {
  dropdata: {
    option: string;
    icon?: any;
  }[];
  placeholder?: string;
  defaultSelectedIndex?: number;
}
export default function Dropdown({
  dropdata,
  placeholder,
  defaultSelectedIndex,
}: PropTypes) {
  const [selected, setSelected] = useState(typeof defaultSelectedIndex === 'number' ? dropdata[defaultSelectedIndex] : null);

  return (
    <div className="dropdown">
      <button className="dropdown__header">
        {selected?.icon && (
          <img
            className="dropdown__header-icon"
            src={selected.icon}
            alt={selected.option}
          />
        )}
        <span className="dropdown__header-text">
          {selected ? selected.option : placeholder}
        </span>
        <div className="dropdown__arrow">
          <Arrow />
        </div>
      </button>
      <div className="dropdown__content">
        {dropdata?.map((item, index) => (
          <button
            key={index}
            onClick={() => setSelected(item)}
            className="dropdown__item"
          >
            <>
              {item.icon && (
                <img
                  src={item.icon}
                  alt={item.option}
                  className="dropdown__item-icon"
                />
              )}
              <span className="dropdown__item-text">{item.option}</span>
            </>
          </button>
        ))}
      </div>
    </div>
  );
}
