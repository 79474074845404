import Project from 'components/Shared/TableCells/Project';
import WithArrow from 'components/Shared/TableCells/WithArrow';
import PrimaryText from 'components/Shared/TableCells/PrimaryText';
import { useMemo } from 'react';
import ListableTable from 'components/UI/ListableTable';
import './style.scss'

interface PropTypes {
  data: {
    project?: { name?: string; detail?: string; image?: string };
    category?: string;
    profit?: { type?: string; value?: string };
    date?: string;
  }[];
}
export default function Performancetable({ data }: PropTypes) {
  const columns = ['Project', 'Category', 'Profit', 'Date'];

  const transformedData = useMemo(() => {
    return data.map((item) => [
      <Project
        project={item?.project?.name}
        detail={item?.project?.detail}
      />,
      <PrimaryText text={item.category} />,
      <WithArrow type={item?.profit?.type} value={`${item?.profit?.value}%`} />,
      <PrimaryText text={item.date} />,
    ]);
  }, [data]);

  return (
    <ListableTable
      data={transformedData}
      id="performance-trades-table"
      templateClass="performance-trades-row"
      columns={columns}
      showHeader
    />
  );
}
