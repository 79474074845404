import './style.scss';
import { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
import { ReactComponent as StakeIcon } from 'assets/icons/user.svg';
import { ReactComponent as WithdralsIcon } from 'assets/icons/analitycs.svg';
import { ReactComponent as PerformanceIcon } from 'assets/icons/Menu.svg';
import { ReactComponent as LeaderboardIcon } from 'assets/icons/star-favorite-circle.svg';
import { ReactComponent as LockIcon } from 'assets/icons/Lock.svg';
import { ReactComponent as BuyIcon } from 'assets/icons/dollar.svg';
import { ReactComponent as DblIcon } from 'assets/icons/dblcard.svg';
import { ReactComponent as LearnIcon } from 'assets/icons/learn.svg';
import { ReactComponent as TradeIcon } from 'assets/icons/trade.svg';
import { NavLink } from 'react-router-dom';
import Logo from 'components/Shared/Logo';
import Footer from '../Footer';

interface ItemProps {
  icon: React.ReactNode;
  title: string;
  badge: string;
  link: string;
  isLocked?: boolean;
}

const items: ItemProps[] = [
  {
    icon: <DashboardIcon />,
    title: 'Dashboard',
    badge: '001',
    link: '/dashboard',
  },
  {
    icon: <StakeIcon />,
    title: 'Stake & Invest',
    badge: '',
    link: '/stake',
  },
  {
    icon: <WithdralsIcon />,
    title: 'Withdrawals',
    badge: '',
    link: '/withdrawals',
  },
  {
    icon: <PerformanceIcon />,
    title: 'Perfomance',
    badge: '',
    link: '/performance',
  },
  {
    icon: <LeaderboardIcon />,
    title: 'Leaderboard',
    badge: '',
    link: '/leaderboard',
  },
  {
    icon: <BuyIcon />,
    title: 'Buy crypto',
    badge: '',
    link: '/buyCrypto',
    isLocked: true,
  },
  {
    icon: <DblIcon />,
    title: 'DBL card',
    badge: '',
    link: '/walletManagment',
  },
  {
    icon: <LearnIcon />,
    title: 'Learn',
    badge: '',
    link: '/learn',
  },
  {
    icon: <TradeIcon />,
    title: 'Trade Dashboard',
    badge: '',
    link: '/tradeDashboard',
  },
];

export default function Sidebar({ onToggle, isOpen }: { onToggle: () => void, isOpen: boolean }) {
  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <Logo />
      </div>
      <div className="sidebar__menu">
        <div className="sidebar__title"> / Main Menu</div>
        <div className="sidebar__menu-items">
          {items.map((item) => (
            <NavLink
              to={item.link}
              className={({ isActive }) =>
                'sidebar__menu-item ' +
                (isActive ? 'active' : '')
              }
              onClick={() => {
                isOpen && onToggle();
              }}
              key={`sidebar-item-${item.link}`}
            >
              <div className="sidebar__menu-item--icon">
                {item.icon}
              </div>
              <div className="sidebar__menu-item--title">{item.title}</div>
              <div className="sidebar__menu-item--badge">
                {item.badge}
              </div>
              {item.isLocked && (
                <button className="sidebar__menu-item--lock">
                  <LockIcon />
                </button>
              )}
            </NavLink>
          ))}
        </div>
        <hr className="sidebar__divider" />
        <Footer />
      </div>
    </div>
  );
}
