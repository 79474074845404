import Button from 'components/UI/Button';
import Image from 'assets/images/image.png';
import Input from 'components/UI/Input';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import OTP from 'components/UI/OTP';

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [verification, setVerification] = useState(false);
  const [otp, setOtp] = useState('');

  const handleOtpChange = (value: string) => {
    setOtp(value);
  }

  return (
    <div className="forgot-password-container">
      <div className="main-content__body">
        <div className="main-content__header">
          <div className="main-content__icon">
            <img
              src={Image}
              className='main-content__icon-file'
              alt=""
            />
          </div>
          <div className="main-content__title">Forget Password</div>
          <div className="main-content__text">
            Trusted by millions. Creso is a secure wallet making the
            world of <span className='highlighted'>web3</span> accessible to all.
          </div>
        </div>
        {!verification ? (
          <div className="main-content__form">
              <Input
                label="Phone Number"
                icon={<PhoneIcon />}
                placeholder="Enter Your Phone number"
              />
          </div>
          ) : (
            <div className="centered">
              <OTP  
                value={otp}
                label="Verification Code"
                length={6}
                id="verification"
                onChange={handleOtpChange}
              />
            </div>
          )}
        </div>

      <div className="main-content__footer">
        <div className="main-content__footer-content">
          <span className="main-content__text text-normal">
            if you need help
            <Link className="highlighted" to="/signup"> Contact Us</Link>
          </span>
        </div>
        {verification ? (
          <div
            className="main-content__button"
            onClick={() => navigate('/')}
          >
            <Button
              title="Login"
              variant="primary"
              badge='001'
            />
          </div>
        ) : (
          <div
            className="main-button"
            onClick={() => setVerification(true)}
          >
            <Button
              title="Next"
              variant="primary"
              badge='001'
            />
          </div>
        )}
      </div>
    </div>
  );
}
