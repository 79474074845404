import TokenImage from 'assets/images/tether.png';
import Button from 'components/UI/Button';
import { ReactComponent as Exclamation } from 'assets/icons/exclamation.svg';
import './style.scss';

interface PropTypes {
  data: { price: string; wallet: string; withdrawable: string };
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function WithdrawalCard({
  data,
  setModal,
}: PropTypes) {
  return (
    <div className="withdraw-card">
      <div className="withdraw-card__info">
        <Exclamation />
      </div>
      <div className="withdraw-card__header">
        <div className="withdraw-card__image">
          <img
            src={`${TokenImage}`}
            alt="Token"
          />
        </div>
        <div className="withdraw-card__value"> ${data.price}</div>
        <div className="withdraw-card__address">{data.wallet}</div>
      </div>
      <div className="withdraw-card__body">
        <div className="withdraw-card__amount">
          <span className="withdraw-card__label">Withdrawable:</span>
          <span className="withdraw-card__amount-value">{data.withdrawable}<span>$</span></span>
        </div>
        <Button
          title="Request withdraw"
          variant="secondary"
          onClick={() => setModal(true)}
        />
      </div>
    </div>
  );
}
