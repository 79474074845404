import './style.scss';
import { ReactComponent as Arrow } from 'assets/icons/arrowdown.svg';
import { useState } from 'react';

interface PropTypes extends dataTypes {
  label?: string;
}

interface dataTypes {
  data:
    {
        img?: string;
        type?: string;
        value?: string;
        icon?: string;
      }[]
    | any[];
}

export default function Select({ data, label }: PropTypes) {
  const [active, setActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>();

  return (
    <div className="custom-select">
      {label && <div className="custom-select__label">{label}</div>}

      <div
        className={'custom-select__wrapper ' + (active ? 'custom-select__wrapper--active' : '')}
        onClick={() => setActive(!active)}
      >
        <div className="custom-select__header">
          {selectedValue?.icon && (
            <div className="custom-select__icon">
              {selectedValue.icon}
            </div>
          )}
          <div className="custom-select__text">{selectedValue?.value || label || "Select"}</div>
          <div className="custom-select__arrow">
            <Arrow />
          </div>
        </div>
        <div className="custom-select__list">
          {data?.map((item, index) => (
            <div
              className="custom-select__item"
              onClick={() => setSelectedValue(item)}
              key={index + item.value}
            >
              {item?.icon && (
                <div className="custom-select__item-icon">
                  {item.icon}
                </div>
              )}
              <div className="custom-select__item-text">{item.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
