import WithScale from 'components/Shared/TableCells/WithScale';
import Button from 'components/UI/Button';
import Status from 'components/Shared/TableCells/Status';
import SecondaryText from 'components/Shared/TableCells/SecondaryText';
import PrimaryText from 'components/Shared/TableCells/PrimaryText';
import './style.scss';
import { useMemo } from 'react';
import ListableTable from 'components/UI/ListableTable';

interface PropTypes {
  data: {
    amount?: string;
    date?: string;
    price?: string;
    status?: string;
  }[];
}
export default function RequestTable({ data }: PropTypes) {
  const columns = ['Amount', 'Date', 'Status', 'Price', ''];

  const transformedData = useMemo(() => {
    return data.map((item) => [
      <WithScale
        value={item?.amount}
        type="send"
      />,
      <div className="hidden-row"><SecondaryText text={item?.date} /></div>,
      <Status status={item?.status} />,
      <PrimaryText text={`$${item.price}`} />,
      <Button
        variant="secondary"
        title="View Information"
        arrow={true}
      />,
    ]);
  }, [data]);
  
  return (
    <ListableTable
      data={transformedData}
      id="withdrawal-requests-table"
      templateClass="withdrawal-request-row"
      columns={columns}
      withAction
    />
  );
}
