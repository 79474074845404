import { useState } from 'react';
import './style.scss';

interface PropTypes {
  value: string;
  label: string;    
  length: number;
  id: string;
  onChange: (value: string) => void;
}

export default function OTP({ value, length, onChange, label, id }: PropTypes) {
    const [otp, setOtp] = useState<string>(value || '');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const val = e.target.value;
        const newOtp = otp.split('');
        newOtp[index] = val;
        const _otp = newOtp.join('');

        setOtp(_otp);
        onChange(_otp);

        if(val === '' && index > 0) {
            document.getElementById(`otp-${id}-${index - 1}`)?.focus();
        } else if(val !== '' && index < length - 1) {
            document.getElementById(`otp-${id}-${index + 1}`)?.focus();
        }
    }

    const handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.select();
    }
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if ((e.key === 'Backspace' || e.key === 'Delete') && (e.target as HTMLInputElement).value === '' && index > 0) {
            document.getElementById(`otp-${id}-${index - 1}`)?.focus();
        }

        if((e.target as HTMLInputElement).value !== '' && index < length - 1) {
            document.getElementById(`otp-${id}-${index + 1}`)?.focus();
        }

        if(e.key === 'ArrowLeft' && index > 0) {
            document.getElementById(`otp-${id}-${index - 1}`)?.focus();
        }

        if(e.key === 'ArrowRight' && index < length - 1) {
            document.getElementById(`otp-${id}-${index + 1}`)?.focus();
        }   
    }

    return (
        <div className="otp">
            <div className="otp__label">{label || 'Verification code'}</div>
            <div className="otp__input">
                {Array.from({ length }).map((_, index) => (
                    <input 
                        type="text" 
                        placeholder="_"
                        maxLength={1} 
                        key={`otp-${id}-${index}`} 
                        id={`otp-${id}-${index}`} 
                        onChange={(e) => handleChange(e, index)} 
                        value={otp[index] || ''}
                        onFocus={handleFocus}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        className={`otp__input-item ${otp[index] ? 'filled' : ''}`}
                    />
                ))}
            </div>
        </div>
    );
}