import './style.scss';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';

interface PropType {
  status?: string;
}
export default function Status({ status }: PropType) {
  return (
    <div className="status">
      <ClockIcon />
      {status}
    </div>
  );
}
