import './style.scss';

export interface ExtraDataProps {
    id: string;
    label: string;
    data: any;
}

export interface ListableTableProps {
    data: any[][];
    extraData?: ExtraDataProps[][];
    columns: string[];
    onRowClick?: (row: any) => void;
    className?: string;
    showHeader?: boolean;
    templateClass?: string;
    extraRowClass?: string;
    id: string;
    withAction?: boolean;
}

export default function ListableTable({
  data,
  columns,
  extraData = [],
  onRowClick,
  className,
  showHeader,
  templateClass,
  extraRowClass,
  id,
  withAction
}: ListableTableProps) {
    return (
        <div className={`listable-table ${className}`} id={id}>
            {showHeader && (
                <div className={`listable-table__header  ${withAction ? 'listable-table__header--action' : ''} ${templateClass}`}>
                    {columns.map((column) => (
                        <div className="listable-table__header-cell" key={column}>
                            {column}
                        </div>
                    ))}
                </div>
            )}
            <div className="listable-table__body">
                {data.map((row, idx) => extraData.length > idx ? (
                    <div className="listable-table__row-group" onClick={() => onRowClick?.(idx)} key={id + "__" + idx}>
                        <div className={`listable-table__row ${templateClass}`}>
                            {columns.map((column, index) => (
                                <div className={`listable-table__cell ${withAction && index === columns.length - 1 ? 'listable-table__cell--action' : ''}`} key={column}>
                                    <div className="listable-table__label">{column}:</div>
                                    <div className="listable-table__value">{row[index]}</div>
                                </div>
                            ))}
                        </div>
                        <div className="listable-table__extra-wrapper">
                            <div className={`listable-table__extra-row ${extraRowClass}`}>
                                {extraData[idx].map((extra, index) => (
                                    <div className={`listable-table__extra-cell ${withAction && index === columns.length - 1 ? 'listable-table__cell--action' : ''}`} key={extra.id + "__" + idx}>
                                        <div className="listable-table__label">{extra.label}:</div>
                                        <div className="listable-table__value">{extra.data}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={`listable-table__row ${withAction ? 'listable-table__row--action' : ''} ${templateClass}`} key={id + "__" + idx} onClick={() => onRowClick?.(idx)}>
                        {columns.map((column, index) => (
                            <div className={`listable-table__cell ${withAction && index === columns.length - 1 ? 'listable-table__cell--action' : ''}`} key={column}>
                                {withAction && index < columns.length - 1 ? (<div className="listable-table__label">{column}:</div>) : null}
                                <div className="listable-table__value">{row[index]}</div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}