import Button from 'components/UI/Button';
import KYCImage from 'assets/images/kyc.png';
import './style.scss';

interface PropTypes {
  userType: string;
}
export default function KYC({ userType }: PropTypes) {
  return (
    <div className="kyc">
      <div className="kyc__title">KYC Management</div>
      <div className="kyc__content">
        <img
          src={KYCImage}
          className="kyc__image"
          alt=""
        />
        <div
          className={`kyc__status kyc__status--${userType}`}
        >
          {userType === 'pro' ? 'KYC confirmed' : userType === 'confirmed' ? 'KYC Confirming' : 'KYC Not Confirmed'}
        </div>
        <div className="kyc__description">
          Sorry, you are unable to proceed until you complete the
          backup. Backup now to secure your account.
        </div>
        <div className="kyc__button">
          <Button
            variant="secondary"
            title={userType === 'pro' ? 'KYC information' : 'Start KYC'}
          />
        </div>
      </div>
    </div>
  );
}
