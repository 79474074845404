import './style.scss';
import Input from 'components/UI/Input';
import { ReactComponent as UserIcon } from 'assets/icons/user2.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/password.svg';
import { ReactComponent as NameIcon } from 'assets/icons/user3.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';

import Button from 'components/UI/Button';
export default function Accountform() {
  return (
    <div className="profile-form">
      <div className="profile-form__wrapper">
        <div className="profile-form__title">Account Information</div>
        <div className="profile-form__body">
          <div className="profile-form__row">
            <Input
              label="Username"
              icon={<UserIcon />}
              placeholder="Enter Your Username"
            />
            <Input
              label="Username"
              icon={<NameIcon />}
              placeholder="Enter Your Full Name"
            />
          </div>
          <Input
            label="Country Of Residence"
            icon={<LocationIcon />}
            placeholder="Enter Your Address"
          />
          <div className="profile-form__row">
            <Input
              label="Email"
              icon={<EmailIcon />}
              placeholder="Enter Your Email"
              type="email"
            />
            <Input
              label="Phone Number"
              icon={<PhoneIcon />}
              placeholder="Enter Your Phone Number"
            />
          </div>
          <Input
            icon={<PasswordIcon />}
            label="Password"
            placeholder="Enter Your password"
            button="Show"
            type="password"
          />
        </div>
      </div>
      <div className="profile-form__logout">
        <Button
          variant="info"
          title="Logout"
          badge='003'
          />
      </div>
    </div>
  );
}
