import Profile from 'components/Shared/Profile';
import SecondaryText from 'components/Shared/TableCells/SecondaryText';
import WithUnite from 'components/Shared/TableCells/WithUnit';
import PrimaryText from 'components/Shared/TableCells/PrimaryText';
import { useMemo } from 'react';
import ListableTable from 'components/UI/ListableTable';
import './style.scss';

interface PropTypes {
  data: {
    name: {
      type?: string;
      name: string;
      avatar: string;
    };
    wallet?: string;
    staked?: string;
    apy?: string;
    profit?: string;
  }[];
}
export default function LeaderboardTable({ data }: PropTypes) {
  const columns = ['#', 'Wallet', 'Staked Amount', 'APY', 'Profit'];

  const transformedData = useMemo(() => {
    return data.map((item, index) => [
      (
          <div className="leaderboard-table__profile">
            <span className="leaderboard-table__rank">{`${index < 9 ? '0' : ''}${index + 1}`}</span>
            <Profile userType='active' name={item.name.name} avatar={item?.name?.avatar} hideType />
          </div>
      ),
      <SecondaryText text="0xf34daw12...13ge" />,
      <WithUnite unit="USDT" num={item.staked} />,
      <WithUnite unit="%" num={item.apy} />,
      <PrimaryText text={`${item.profit}`} />,
    ]);
  }, [data]);
  
  return (
    <ListableTable
      data={transformedData}
      id="leaderboard-table"
      templateClass="leaderboard-table__row"
      columns={columns}
      showHeader
    />
  );
}
