import { Link } from 'react-router-dom';
import Button from 'components/UI/Button';
import Image from 'assets/images/image.png';
import Input from 'components/UI/Input';
import { ReactComponent as UserIcon } from 'assets/icons/user2.svg';
import { ReactComponent as NameIcon } from 'assets/icons/user3.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as LocationIcon } from 'assets/icons/location.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/password.svg';

export default function Signup() {
  return (
    <div className="signup-container">
      <div className="main-content__body">
        <div className="main-content__header">
          <div className="main-content__icon">
            <img
              className="main-content__icon-file"
              src={Image}
              alt="Authentication"
            />
          </div>
          <div className="main-content__title">Let's Get Started!</div>
          <div className="main-content__text">
            Trusted by millions. Digital Bank Labs is a secure investing platform making the
            world of <span className='highlighted'>web3</span> accessible to all.
          </div>

        </div>
        <div className="main-content__form">
          <div className="main-content__form-row">
            <Input
              label="Username"
              icon={<UserIcon />}
              placeholder="Enter Your Username"
            />
            <Input
              label="Full Name"
              icon={<NameIcon />}
              placeholder="Enter Your Full Name"
            />
          </div>
          <Input
            label="Date Of Birthday"
            icon={<CalendarIcon />}
            type="date"
          />
          <Input
            label="Email"
            icon={<EmailIcon />}
            placeholder="Enter Your Email"
            type="email"
          />
          <Input
            label="Country Of Residence"
            icon={<LocationIcon />}
            placeholder="Enter Your Country"
          />
          <div className="main-content__form-row">
            <Input
              label="Password"
              icon={<PasswordIcon />}
              placeholder="Enter Your Password"
              button="show"
              type="password"
            />
            <Input
              label="Repeat Password"
              icon={<PasswordIcon />}
              placeholder="Enter Your Password"
              button="show"
              type="password"
            />
          </div>
        </div>
      </div>
      <div className="main-content__footer">
        <div className="main-content__footer-content">
          <span className="main-content__text text-normal">
            if you Already have an account
            <Link className="highlighted" to="/"> Login Now</Link>
          </span>
        </div>
        <div className="main-content__button">
          <Button
            title="Signup"
            variant="primary"
            badge='001'
          />
        </div>
      </div>
    </div>
  );
}
