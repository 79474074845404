import './style.scss';
import Subtitle from 'components/UI/Subtitle';
import Title from 'components/UI/Title';
import { ReactComponent as AssetIcon } from 'assets/icons/allAssets.svg';
import TradeTable from 'components/TradeDashboard/TradeTable';
import Bitcoin from 'assets/images/bitcoin.png';
import Select from 'components/UI/Select';
import TradeTabs from 'components/TradeDashboard/TradeTabs';
import { useState } from 'react';

const tableData = [
  {
    project: {
      image1: Bitcoin,
      image2: Bitcoin,
      token: 'BTC/USDT',
      date: '2024-07-14 02:00:01',
      status: 'Closed Short',
    },
    trader: 'Samuel haw',
    closedPL: '4,069.0854',
    QTY: '17.347',
    entryPrice: '95,987',
    exitPrice: '95,987',
    openingFee: '103',
    closingFee: '103.654829',
    fundingFee: '103.654829',
    exitType: 'Trade',
  },
  {
    project: {
      image1: Bitcoin,
      image2: Bitcoin,
      token: 'BTC/USDT',
      date: '2024-07-14 02:00:01',
      status: 'Closed Short',
    },
    trader: 'Samuel haw',
    closedPL: '4,069.0854',
    QTY: '17.347',
    entryPrice: '95,987',
    exitPrice: '95,987',
    openingFee: '103',
    closingFee: '103.654829',
    fundingFee: '103.654829',
    exitType: 'Trade',
  },
  {
    project: {
      image1: Bitcoin,
      image2: Bitcoin,
      token: 'BTC/USDT',
      date: '2024-07-14 02:00:01',
      status: 'Closed Short',
    },
    trader: 'Samuel haw',
    closedPL: '4,069.0854',
    QTY: '17.347',
    entryPrice: '95,987',
    exitPrice: '95,987',
    openingFee: '103',
    closingFee: '103.654829',
    fundingFee: '103.654829',
    exitType: 'Trade',
  },
];

export default function TradeDashboard() {
  const [activeTab, setActiveTab] = useState(3);


  return (
    <div className="trading">
      <div className="trading__header">
        <div className="trading__header-left">
          <Subtitle
            subtitle="All Assets"
            icon={<AssetIcon />}
          />
          <Title
            mainTitle="Trade Dashboard"
            badge="[003]"
          />
        </div>
        <div className="trading__header-right">
          <Select
            data={[
              { value: 'Closed Orders' },
              { value: 'Open Orders' },
              { value: 'Next Orders' },
              { value: 'All Orders' },
            ]}
          />
          <Select
            data={[
              { value: 'BTC/USDT' },
              { value: 'ETHER/USDT' },
              { value: 'SOL/USDT' },
            ]}
          />
        </div>
      </div>
      <div className="trading__body">
        <TradeTabs
          data={['Spot', 'Margin', 'P&L']}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
        <TradeTable data={tableData} />
      </div>
    </div>
  )
}
