import Subtitle from 'components/UI/Subtitle';
import Title from 'components/UI/Title';
import { ReactComponent as SubIcon } from 'assets/icons/allAssets.svg';
import RequestTable from 'components/withdrawals/RequestTable';
import WithdrawalCard from 'components/withdrawals/WithdrawalCard';
import WithdrawalModal from 'components/withdrawals/WithdrwalModal';
import { useState } from 'react';
import './style.scss';

const tableData = [
  {
    amount: '53298565',
    date: '14:21 - 6/8/2024',
    price: '6333.5',
    status: 'Pending',
  },
  {
    amount: '53298565',
    date: '14:21 - 6/8/2024',
    price: '6333.5',
    status: 'Pending',
  },
  {
    amount: '53298565',
    date: '14:21 - 6/8/2024',
    price: '6333.5',
    status: 'Pending',
  },
];

const cardsData = [
  {
    price: '235,211.97',
    wallet: '0x259ffce3...1354',
    withdrawable: '716,456,78',
  },
  {
    price: '235,211.97',
    wallet: '0x259ffce3...1354',
    withdrawable: '716,456,78',
  },
  {
    price: '235,211.97',
    wallet: '0x259ffce3...1354',
    withdrawable: '716,456,78',
  },
  {
    price: '235,211.97',
    wallet: '0x259ffce3...1354',
    withdrawable: '716,456,78',
  },
  {
    price: '235,211.97',
    wallet: '0x259ffce3...1354',
    withdrawable: '716,456,78',
  },
  {
    price: '235,211.97',
    wallet: '0x259ffce3...1354',
    withdrawable: '716,456,78',
  },
];

export default function Withdrawal() {
  const [modal, setModal] = useState(false);

  return (
    <div className="withdrawal">
      <WithdrawalModal
        modal={modal}
        setModal={setModal}
      />
      <div className="withdrawal__row">
        <div className="withdrawal__header">
          <Subtitle
            subtitle="All Assets"
            icon={<SubIcon />}
          />
          <Title
            mainTitle="Pending Requests"
            badge="[001]"
          />
        </div>
        <div className="withdrawal__body">
          <RequestTable data={tableData} />
        </div>
      </div>
      <div className="withdrawal__row">
        <div className="withdrawal__header">
          <Subtitle
            subtitle="All Assets"
            icon={<SubIcon />}
          />
          <Title
            mainTitle="Withdrawals"
            badge="[002]"
          />
        </div>
        <div className="withdrawal__content">
          {cardsData.map((item, index) => (
            <WithdrawalCard
              setModal={setModal}
              data={item}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
