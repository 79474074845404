import './style.scss';

interface PropTypes {
  icon: React.ReactNode;
  title: string;
  variant: string;
}
export default function ActionButton({
  icon,
  title,
  variant,
}: PropTypes) {
  return (
    <button
      className={
        'action-button ' +
        (variant === 'red'
          ? 'red'
          : variant === 'green'
          ? 'green'
          : variant === 'white'
          ? 'white'
          : variant === 'orange'
          ? 'orange'
          : '')
      }
    >
      <div>{variant === 'white' ? title : icon}</div>
      <div>{variant === 'white' ? icon : title}</div>
    </button>
  );
}
