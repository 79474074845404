import { ReactComponent as Check } from 'assets/icons/check.svg';
import './style.scss';

interface PropTypes {
  selected?: boolean;
  onSelect?: () => void;
  onMore?: () => void;
  data?: {
    number?: string;
    min?: string;
    payout?: string;
    apy?: string;
    locktime: string;
  };
}

export default function PlanCard({ selected, data, onSelect, onMore }: PropTypes) {


  return (
    <div className={`investment-card ${selected ? 'active' : ''}`} onClick={onSelect}>
      <div className="investment-card__head">
        <div className="investment-card__selected">
          <Check />
        </div>
        <div className="investment-card__id">
          {data?.number} <sup>Y</sup>
        </div>
        <div className="investment-card__apy">
          {data?.apy}% APY
        </div>
      </div>
      <div className="investment-card__body">
        <div className="investment-card__row">
          <div className="investment-card__label">Minimum Investment:</div>
          <div className="investment-card__value">${data?.min}</div>
        </div>
        <div className="investment-card__row">
          <div className="investment-card__label">Payout Distribution:</div>
          <div className="investment-card__value">{data?.payout}<span>Months</span></div>
        </div>
        <div className="investment-card__row">
          <div className="investment-card__label">APY:</div>
          <div className="investment-card__value">{data?.apy}<span>%</span></div>
        </div>
        <div className="investment-card__row">
          <div className="investment-card__label">Lock Time:</div>
          <div className="investment-card__value">{data?.locktime}<span>Years</span></div>
        </div>
      </div>
      <div className="investment-card__action">
        <button onClick={onMore}>See More</button>
      </div>
    </div>
  )
}
