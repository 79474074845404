import './style.scss';
import Subtitle from 'components/UI/Subtitle';
import Title from 'components/UI/Title';
import { ReactComponent as AssetIcon } from 'assets/icons/allAssets.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Button from 'components/UI/Button';
import WalletManagmentTable from 'components/WalletManagment/WalletManagementTable';
import Bitcoin from 'assets/images/bitcoin.png';

const tableData = [
  {
    token: {
      image: Bitcoin,
      project: 'Block Chain Investment',
      detail: 'Bitcoin',
    },
    price: '$450,234.01',
    balance: '$12,324.01',
  },
  {
    token: {
      image: Bitcoin,
      project: 'Block Chain Investment',
      detail: 'Bitcoin',
    },
    price: '$450,234.01',
    balance: '$12,324.01',
  },
  {
    token: {
      image: Bitcoin,
      project: 'Block Chain Investment',
      detail: 'Bitcoin',
    },
    price: '$450,234.01',
    balance: '$12,324.01',
  },
  {
    token: {
      image: Bitcoin,
      project: 'Block Chain Investment',
      detail: 'Bitcoin',
    },
    price: '$450,234.01',
    balance: '$12,324.01',
  },
];

export default function WalletManagment() {
  return (
    <div className="wallet">
      <div className="wallet__header">
        <div className="wallet__header-left">
          <Subtitle
            subtitle="All Assets"
            icon={<AssetIcon />}
          />
          <Title
            mainTitle="Wallet Managment"
            badge="[003]"
          />
        </div>
        <div className="wallet__header-right">
          <div>
            <Button
              variant="secondary"
              title="Withdraw"
              icon={<PlusIcon />}
            />
          </div>
          <div>
            <Button
              variant="primary"
              title="Buy Crypto"
              icon={<PlusIcon />}
            />
          </div>
        </div>
      </div>
      <div className="wallet__body">
        <WalletManagmentTable data={tableData} />
      </div>
    </div>
  );
}
