import './style.scss';
import { ReactComponent as Arrow } from 'assets/icons/arrowdown.svg';
import { useState } from 'react';

interface PropTypes {
  data: any[];
}

export default function SelectBox({ data }: PropTypes) {
  const [active, setActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [amount, setAmount] = useState('');
  return (
    <div className="select-box">
      <div
        className={'select ' + (active ? 'active' : '')}
        onClick={() => setActive(!active)}
      >
        <div className="select-btn">
          {selectedValue ? (
            <>
              {selectedValue.img ? (
                <div className="select-value">
                  <img src={selectedValue.img} alt="" />
                  {selectedValue.crypto}
                </div>
              ) : (
                <div>{selectedValue}</div>
              )}
              <div
                style={active === true ? { rotate: '180deg' } : {}}
              >
                <Arrow />
              </div>
            </>
          ) : (
            <>
              Select
              <div
                style={active === true ? { rotate: '180deg' } : {}}
              >
                <Arrow />
              </div>
            </>
          )}
        </div>
        <div className="select-content">
          {data.map((item, index) => (
            <div
              className="select-item"
              onClick={() => setSelectedValue(item)}
              key={index}
            >
              {item.img ? (
                <div className="select-value">
                  <img src={item.img} alt="" />
                  {item.crypto}
                </div>
              ) : (
                <div>{item}</div>
              )}
            </div>
          ))}
        </div>
      </div>
      <input
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        type="text"
        placeholder="Enter Amount"
      />
      <button>Max</button>
    </div>
  );
}
