interface Proptypes {
  text?: string;
}
export default function SecondaryText({ text }: Proptypes) {
  return (
    <div
      style={{
        color: '#6c6c70',
        width: '130px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {text}
    </div>
  );
}
