import './style.scss';
import Title from 'components/UI/Title';
import Select from 'components/UI/Select';
import Tether from 'assets/images/tether.png';
import Bitcoin from 'assets/images/bitcoin.png';
import { ReactComponent as Arrow } from 'assets/icons/arrowdown.svg';

export default function LockedSection() {
  return (
    <div className="lock">
      <div className="lock__calculator">
        <div className="lock__entry">
          <Title
            mainTitle="Locked Amount"
            badge="[003]"
          />

          <div className="token-input">
            <div className="token-input__body">
              <div className="token-input__info">
                <img className="token-input__img" src={Tether} alt="tether" />
                <div className="token-input__name">Tether</div>
                <div className="token-input__arrow">
                  <Arrow />
                </div>
              </div>
              <input 
                className="token-input__input" 
                placeholder="Enter Amount"
                pattern={`^[0-9]*[.,]?[0-9]{0,18}$`}
                inputMode="decimal"
                min="0" 
                />
              <button className="token-input__max">MAX</button>
            </div>
            <div className="token-input__balance">
              <span className="token-input__label">Your Balance:</span>
              <span className="token-input__value">139.974</span>
            </div>
          </div>

        </div>


        <div className="lock__calculation">
          <div className="lock__calculation-row">
            <div className="lock__calculation-item">
              <div className="lock__calculation-item-inner">
                <div className="lock__calculation-title">Share:</div>
                <div className="lock__calculation-value">0.0007<span className="lock__calculation-unit">%</span></div>
              </div>
            </div>
            <div className="lock__calculation-item">
              <div className="lock__calculation-item-inner">
                <div className="lock__calculation-title">Tokens per Year:</div>
                <div className="lock__calculation-value">506<span className="lock__calculation-unit">USDT</span></div>
              </div>
            </div>
          </div>
          <div className="lock__calculation-row lock__calculation-row--highlighted">
            <div className="lock__calculation-item">
              <div className="lock__calculation-item-inner">
                <div className="lock__calculation-title">Earning:</div>
                <div className="lock__calculation-value">$4,154,320.98<span className="lock__calculation-unit">3,214,123.12 USDT</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lock__details">
        <div className="lock__details-item">
          <div className="lock__details-item-inner">
            <div className="lock__details-title">Stake Date:</div>
            <div className="lock__details-value">June 6 , 2022 06:45 AM</div>
          </div>
        </div>
        <div className="lock__details-item">
          <div className="lock__details-item-inner">
            <div className="lock__details-title">Value Date:</div>
            <div className="lock__details-value">June 7 , 2022 05:00 AM</div>
          </div>
        </div>
        <div className="lock__details-item">
          <div className="lock__details-item-inner">
            <div className="lock__details-title">interest end time:</div>
            <div className="lock__details-value">Aug 3 , 2022 05:00 AM</div>
          </div>
        </div>
        <div className="lock__details-item">
          <div className="lock__details-item-inner">
            <div className="lock__details-title">Redemption date:</div>
            <div className="lock__details-value">Aug 6 , 2022 05:00 AM</div>
          </div>
        </div>
      </div>
    </div>
  );
}
