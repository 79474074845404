import './style.scss';
import Subtitle from 'components/UI/Subtitle';
import Title from 'components/UI/Title';
import { ReactComponent as AssetIcon } from 'assets/icons/allAssets.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import Image1 from 'assets/images/image1.png';
import Image2 from 'assets/images/image2.png';
import Button from 'components/UI/Button';
import LearnCard from 'components/Learn/LearnCard';

const cardsData = [
  {
    title:
      'BITCOIN HOLDERS! I FOUND WHO IS ACTUAL FOUND WHO IS ACTUAL',
    image: Image1,
  },
  {
    title: 'BITCOIN HOLDERS! I FOUND WHO IS ACTUAL',
    image: Image2,
  },
  {
    title: 'BITCOIN HOLDERS! I FOUND WHO IS ACTUAL',
    image: Image1,
  },
  {
    title: 'BITCOIN HOLDERS! I FOUND WHO IS ACTUAL',
    image: Image2,
  },
  {
    title: 'BITCOIN HOLDERS! I FOUND WHO IS ACTUAL',
    image: Image1,
  },
  {
    title: 'BITCOIN HOLDERS! I FOUND WHO IS ACTUAL',
    image: Image2,
  },
];

export default function Learn() {
  return (
    <div className="learn">
      <div className="learn__header">
        <div className="learn__header-left">
          <Subtitle
            subtitle="All Assets"
            icon={<AssetIcon />}
          />
          <Title
            mainTitle="Learn Crypto"
            badge="[003]"
          />
        </div>
        <div className="learn__header-right">
          <Button
            variant="primary"
            title="Show More"
            icon={<PlusIcon />}
          />
        </div>
      </div>
      <div className="learn__body">
        {cardsData.map((item, index) => (
          <LearnCard data={item} />
        ))}
      </div>
    </div>
  );
}
