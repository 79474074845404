import { Route, Routes } from 'react-router-dom';
import DashboardLayout from 'components/layout/DashboardLayout';
import Dashboard from 'Views/Dashboard';
import Stake from 'Views/Stake';
import Withdrawal from 'Views/Withdrawal';
import Performance from 'Views/Performance';
import Leaderboard from 'Views/Leaderboard';
import BuyCrypto from 'Views/BuyCrypto';
import './style.scss';
import MainLayout from '../MainLayout';
import Login from 'Views/Login';
import Signup from 'Views/Signup';
import ForgotPassword from 'Views/ForgotPassword';
import ProfileSetting from 'Views/ProfileSetting';
import WalletManagment from 'Views/WalletManagment';
import Learn from 'Views/Learn';
import TradeDashboard from 'Views/TradeDashboard';

// @todo: update routes and use lazy
export default function Container() {
  return (
    <div className="container">
      <Routes>
        <Route element={<MainLayout />}>
          <Route
            path="/"
            element={<Login />}
          ></Route>
          <Route
            path="/signup"
            element={<Signup />}
          ></Route>
          <Route
            path="/forgotpassword"
            element={<ForgotPassword />}
          ></Route>
        </Route>

        <Route element={<DashboardLayout />}>
          <Route
            path="/dashboard"
            element={<Dashboard />}
          ></Route>
          <Route
            path="/stake"
            element={<Stake />}
          ></Route>
          <Route
            path="/withdrawals"
            element={<Withdrawal />}
          ></Route>
          <Route
            path="/performance"
            element={<Performance />}
          ></Route>
          <Route
            path="/leaderboard"
            element={<Leaderboard />}
          ></Route>
          <Route
            path="/buyCrypto"
            element={<BuyCrypto />}
          ></Route>
          <Route
            path="/profile"
            element={<ProfileSetting />}
          ></Route>
          <Route
            path="/walletManagment"
            element={<WalletManagment />}
          ></Route>
          <Route
            path="/learn"
            element={<Learn />}
          ></Route>
          <Route
            path="/tradeDashboard"
            element={<TradeDashboard />}
          ></Route>
        </Route>
      </Routes>
    </div>
  );
}
