import WithScale from 'components/Shared/TableCells/WithScale';
import Button from 'components/UI/Button';
import ListableTable from 'components/UI/ListableTable';
import { useMemo } from 'react';
import './styles.scss'

interface PropTypes {
  data: {
    amount?: string;
    link?: string;
  }[];
}
export default function WithdrawalTable({ data }: PropTypes) {
  const columns = ['Amount', 'Link'];

  const transformedData = useMemo(() => {
    return data.map((item) => [
      <WithScale
        value={item?.amount}
        type="receive"
      />,
      <Button
        variant="secondary"
        title="View Transaction"
        arrow={true}
      />
    ]);
  }, [data]);
  
  return (
    <ListableTable
      data={transformedData}
      id="dashboard-withdraw-table"
      templateClass="dashboard-withdraw-row"
      columns={columns}
      withAction
    />
  );
}
