import Project from 'components/Shared/TableCells/Project';
import PrimaryText from 'components/Shared/TableCells/PrimaryText';
import ActionButton from 'components/Shared/TableCells/ActionButton';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { useMemo } from 'react';
import ListableTable from 'components/UI/ListableTable';
import './style.scss'

interface PropTypes {
  data: {
    token: {
      image: string;
      project: string;
      detail: string;
    };
    price: string;
    balance: string;
  }[];
}
export default function WalletManagmentTable({ data }: PropTypes) {
  const columns = ['Token', 'Price', 'Balance', ''];

  const transformedData = useMemo(() => {
    return data.map((item) => [
      <Project
        image={item.token.image}
        detail={item.token.detail}
        project={item.token.project}
      />,
      
      <PrimaryText text={item.price} />,
      <PrimaryText text={item.balance} />,
      (
        <div className="wallet-management-row__actions">
          <ActionButton
            icon={<ArrowIcon />}
            variant="white"
            title="Withdraw"
          />
          <ActionButton
            icon={<PlusIcon />}
            variant="white"
            title="Buy More"
          />
        </div>
      ),
    ]);
  }, [data]);
  
  return (
    <ListableTable
      data={transformedData}
      id="wallet-management-table"
      templateClass="wallet-management-row"
      columns={columns}
      withAction
      showHeader
    />
  );
}
