import { useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import './style.scss';
import { Outlet } from 'react-router-dom';

export default function DashboardLayout() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    setIsOpen(io => !io);
  };
  
  return (
    <div className='dashboard'>
      <div className={`dashboard__sidebar ${isOpen ? 'open' : ''}`}>
        <Sidebar onToggle={toggleOpen} isOpen={isOpen} />
      </div>
      <div className="dashboard__container">
        <Header onToggle={toggleOpen} isOpen={isOpen} />
        <div className="dashboard__content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
