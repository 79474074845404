import './style.scss';
import { Outlet } from 'react-router-dom';

export default function Content() {
  return (
    <div className="main-content">
      <div className="main-content__inner">
        <Outlet />
      </div>
    </div>
  );
}
