import Title from 'components/UI/Title';
import './style.scss';
import Dropdown from 'components/UI/Dropdown';
import { ReactComponent as AssetIcon } from 'assets/icons/allAssets.svg';
import Subtitle from 'components/UI/Subtitle';
import TransactionTable from 'components/Dashboard/TransactionTable';
import WithdrawalTable from 'components/Dashboard/WithdrawTable';

interface TransactionTableProps {
  date: string;
  link: string;
  price: string;
  amount: string;
}

interface WithdrawalTableProps {
  amount: string;
  link: string;
}

const txnTableData: TransactionTableProps[] = [
  {
    date: '14:21 - 6/8/2024',
    link: '/#112',
    price: '6333.5',
    amount: '53587478',
  },
  {
    date: '14:21 - 6/8/2024',
    link: '/#112',
    price: '6333.5',
    amount: '53587478',
  },
  {
    date: '14:21 - 6/8/2024',
    link: '/#112',
    price: '6333.5',
    amount: '53587478',
  },
];

const withdrawTableData: WithdrawalTableProps[] = [
  {
    amount: '53298565',
    link: '/#112',
  },
  {
    amount: '53298565',
    link: '/#112',
  },
  {
    amount: '53298565',
    link: '/#112',
  },
];

export default function Dashboard() {
  return (
    <div className="home">
      <div className="home__row">
        <div className="home__cell">
          <div className="home__header">
            <div className='home__header-left'>
              <Subtitle
                subtitle="All Assets"
                icon={<AssetIcon />}
              />
              <Title
                mainTitle="My Deposit"
                badge="[001]"
              />
            </div>
            <Dropdown
              dropdata={[
                { option: 'Week' },
                { option: 'Month' },
                { option: 'Year' },
                { option: '24h' },
              ]}
              defaultSelectedIndex={0}
              placeholder="Period"
            />
          </div>
          <div className="home__body">
            <div className="chart__holder"></div>
          </div>
        </div>

        <div className="home__cell">
          <div className="home__header">
            <div className='home__header-left'>
              <Subtitle
                subtitle="All Assets"
                icon={<AssetIcon />}
              />
              <Title
                mainTitle="Top coin"
                badge="[002]"
              />
            </div>
            <Dropdown
              dropdata={[
                { option: '24h' },
                { option: '7d' },
                { option: '30d' },
                { option: 'Year' },
              ]}
              placeholder="Period"
              defaultSelectedIndex={0}
            />
          </div>
          <div className="home__body">
            <div className="chart__holder"></div>
          </div>
        </div>
      </div>
      <div className="home__row">
        <div className='home__cell'>
          <div className="home__header">
            <div className='home__header-left'>
              <Title
                mainTitle="Last Transactions"
                badge="[003]"
              />
            </div>
          </div>
          <TransactionTable data={txnTableData} />
        </div>
        <div className='home__cell'>
          <div className="home__header">
            <div className='home__header-left'>
              <Title
                mainTitle="Withdraws"
                badge="[004]"
              />
            </div>
          </div>
        <WithdrawalTable data={withdrawTableData} />
        </div>
      </div>
    </div>
  );
}
