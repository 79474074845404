import Subtitle from 'components/UI/Subtitle';
import Title from 'components/UI/Title';

import { ReactComponent as AssetIcon } from 'assets/icons/allAssets.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import Performancetable from 'components/Perfomance/PerformanceTable';
import Button from 'components/UI/Button';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import './style.scss';
import Select from 'components/UI/Select';

const tableData = [
  {
    project: {
      name: 'Block Chain Investment',
      detail: 'Bitcoin',
      image: 'hh',
    },
    category: 'Crypto Currency',

    profit: { type: 'increase', value: '11.14' },
    date: 'AUG 3 , 2022 05:00 AM',
  },
  {
    project: {
      name: 'Block Chain Investment',
      detail: 'Bitcoin',
      image: 'hh',
    },
    category: 'Crypto Currency',

    profit: { type: 'decrease', value: '11.14' },
    date: 'AUG 3 , 2022 05:00 AM',
  },
  {
    project: {
      name: 'Block Chain Investment',
      detail: 'Bitcoin',
      image: 'hh',
    },
    category: 'Crypto Currency',
    profit: { type: 'increase', value: '11.14' },
    date: 'AUG 3 , 2022 05:00 AM',
  },
];

export default function Performance() {
  return (
    <div className="performance">
      <div className="performance__header">
        <div className="performance__header-left">
          <Subtitle
            subtitle="All Assets"
            icon={<AssetIcon />}
          />
          <Title
            mainTitle="Performance"
            badge="[001]"
          />
        </div>
        <Select
          data={[
            { value: '6 months', icon: <ClockIcon /> },
            { value: '1 year', icon: <ClockIcon /> },
            { value: '24h', icon: <ClockIcon /> },
          ]}
        />
      </div>
      <div className="performance__body">
        <Performancetable data={tableData} />
      </div>
      <div className="performance__footer">
        <div className="performance__profit">
            Total Profit: <span>56.02%</span>
        </div>
        <div className="performance__actions">
          <Button
            title="Withdraw"
            icon={<PlusIcon />}
            variant="secondary"
          />
          <Button
            title="Compound"
            icon={<PlusIcon />}
            variant="primary"
          />
        </div>
      </div>
    </div>
  );
}
