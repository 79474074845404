import './style.scss';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface PropTypes {
  title: any | { img?: string; mainTitle?: string; detail?: string };
  show: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}
export default function Modal({
  show,
  setModal,
  title,
  children,
}: PropTypes) {

  return (
    <>
      <div
        className={`backdrop ${show === true ? 'show-backdrop' : ''}`}
      ></div>
      <div className={`modal ${show === true ? 'show' : ''}`}>
        <div className="modal-header">
          <div className="modal-title">
            {typeof title == 'string' ? (
              title
            ) : (
              <div className="inner-title">
                <img
                  src={title.img}
                  alt=""
                />
                <div>
                  <div className="main-title">{title.mainTitle}</div>
                  <div className="detail">{title.detail}</div>
                </div>
              </div>
            )}

            <button onClick={() => setModal(false)}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </>
  );
}
