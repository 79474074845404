import Subtitle from 'components/UI/Subtitle';
import Title from 'components/UI/Title';
import { ReactComponent as SubIcon } from 'assets/icons/allAssets.svg';
import LeaderboardTable from 'components/Leaderboard/LeaderboardTable';
import Tabs from 'components/UI/Tabs';
import './style.scss';
import { useState } from 'react';

const tableData = [
  {
    name: { type: 'active', name: 'Mason Franco', avatar: 'https://randomuser.me/api/portraits/men/1.jpg' },
    wallet: '0xf34daw12...13ge',
    staked: '3,214,123.1',
    apy: '34',
    profit: '633',
  },
  {
    name: { type: 'confirmed', name: 'Mason Franco', avatar: 'https://randomuser.me/api/portraits/men/1.jpg' },
    wallet: '0xf34daw12...13ge',
    staked: '3,214,123.1',
    apy: '34',
    profit: '633',
  },
  {
    name: { type: 'not-confirmed', name: 'Mason Franco', avatar: 'https://randomuser.me/api/portraits/men/1.jpg' },
    wallet: '0xf34daw12...13ge',
    staked: '3,214,123.1',
    apy: '34',
    profit: '633',
  },
  {
    name: { type: 'active', name: 'Mason Franco', avatar: 'https://randomuser.me/api/portraits/men/1.jpg' },
    wallet: '0xf34daw12...13ge',
    staked: '3,214,123.1',
    apy: '34',
    profit: '633',
  },
];

export default function Leaderboard() {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <div className="leaderboard">
      <div className="leaderboard__header">
        <div className="leaderboard__header-left">
          <Subtitle
            subtitle="All Assets"
            icon={<SubIcon />}
          />
          <Title
            mainTitle="Leaderboard"
            badge="[001]"
          />
        </div>
        <div className="leaderboard__header-right">
          <Tabs
            data={['Top Traders', 'Top Brokers']}
            bgColor="#1c1b1f"
            activeBgColor="#0b0c0d"
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
        </div>
      </div>
      <div className="leaderboard__body">
        {activeTab === 1 ? (
          <LeaderboardTable data={tableData} />
        ) : (
          <LeaderboardTable data={tableData} />
        )}
      </div>
    </div>
  );
}
