import Button from 'components/UI/Button';
import './style.scss';

interface PropTypes {
  userType: string;
  onRequestAML: () => void;
}

export default function AML({ userType, onRequestAML }: PropTypes) {
  return (
    <div className="aml">
      <div
        className={`aml__state aml__state--${userType}`}
      >
        {userType === 'pro' ? 'AML confirmed' : userType === 'confirmed' ? 'AML Confirming' : 'AML Not Confirmed'}
      </div>
      <Button
        className="aml__button"
        variant="secondary"
        title="Start AML"
        onClick={onRequestAML}
      />
    </div>
  );
}
