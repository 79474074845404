import WithScale from 'components/Shared/TableCells/WithScale';
import SecondaryText from 'components/Shared/TableCells/SecondaryText';
import PrimaryText from 'components/Shared/TableCells/PrimaryText';
import Links from 'components/Shared/TableCells/Links';
import './styles.scss';
import ListableTable from 'components/UI/ListableTable';
import { useMemo } from 'react';

interface PropTypes {
  data: {
    link?: string;
    price?: string;
    date?: string;
    amount?: string;
  }[];
}
export default function TransactionTable({ data }: PropTypes) {
  const columns = ['Txn ID', 'Date', 'Amount', 'Link'];
  
  const transformedData = useMemo(() => {
    return data.map((item) => [
      <WithScale
        value={item?.amount}
        type="receive"
      />,
      <SecondaryText text={item?.date} />,
      <PrimaryText text={`$${item.price}`} />,
      <Links link={item.link} title="View Transaction" />,
    ]);
  }, [data]);
  
  return (
    <ListableTable
      data={transformedData}
      id="dashboard-transactions-table"
      templateClass="dashboard-transaction-row"
      columns={columns}
    />
  );
}
