import './style.scss';
import { ReactComponent as Recieve } from 'assets/icons/reciveArrow.svg';
import { ReactComponent as Send } from 'assets/icons/sendArrow.svg';

interface PropTypes {
  value?: string;
  type?: 'send' | 'receive';
}
export default function WithScale({ value, type }: PropTypes) {
  return (
    <div className="amount">
      <div className="amount__icon">
        {type === 'receive' ? <Recieve /> : <Send />}
      </div>
      <div className="amount__value">
        {value}
      </div>
    </div>
  );
}
