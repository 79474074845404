import './style.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';

interface PropTypes {
  link?: string;
  title: string;
}
export default function Links({ link, title }: PropTypes) {
  return (
    <div>
      <Link
        to={link != undefined ? link : ''}
        className="link"
      >
        {title}
        <Arrow />
      </Link>
    </div>
  );
}
