interface PropTypes {
  num?: string;
  unit?: string;
}
export default function WithUnite({ num, unit }: PropTypes) {
  return (
    <div>
      {num}
      <span
        style={{
          color: '#6c6c70',
          fontSize: '12px',
          marginLeft: '4px',
        }}
      >
        {unit}
      </span>
    </div>
  );
}
