import { useState } from 'react';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowdown.svg';
import './style.scss';

interface PropTypes {
  icon?: React.ReactNode;
  label?: string;
  placeholder?: string;
  button?: any;
  type?: string;
}
export default function Input({
  icon,
  label,
  placeholder,
  button,
  type,
}: PropTypes) {
  const [showpassword, setShowPassword] = useState(false);

  return (
    <div className="input__container">
      <div className="input__label">{label}</div>
      <div className="input__box">
        <div className="input__icon">{icon}</div>
        <input
          className={`input__input ${type === 'password' ? 'input__input--password' : ''}`}
          type={
            showpassword === true
              ? 'text'
              : showpassword === false && type
              ? type
              : 'text'
          }
          placeholder={placeholder}
        />
        {type === 'date' && (
          <div className="input__arrow">
            <ArrowIcon />
          </div>
        )}

        {type === 'password' && (
          <button className="input__button" onClick={() => setShowPassword(!showpassword)}>
            {showpassword ? 'hide' : 'show'}
          </button>
        )}
      </div>
    </div>
  );
}
