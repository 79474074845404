import './style.scss';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { useState } from 'react';

export default function WalletAddress() {
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [inputValue, setInputValue] = useState(
    '0x353d592691Dae6Fa2a08435C3a488571A098C358'
  );
  return (
    <div className="wallet-address">
      <div className="wallet-address__header">
        <div className="wallet-address__header--title">
          Withdrawal Address :
        </div>
        <button
          className="wallet-address__header--edit"
          onClick={() => setIsReadOnly(!isReadOnly)}
        >
          {isReadOnly ? (
            <>
              {' '}
              <EditIcon />
              Edit
            </>
          ) : (
            <>
              <CheckIcon />
              Save
            </>
          )}
        </button>
      </div>
      <div className="wallet-address__input">
        <input
          onChange={(e) => setInputValue(e.target.value)}
          name="wallet"
          type="text"
          value={inputValue}
          disabled={isReadOnly}
        />
      </div>
    </div>
  );
}
