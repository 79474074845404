import './style.scss';
import Title from 'components/UI/Title';
import WalletAddress from 'components/Shared/WalletAddress';
import { ReactComponent as ReferralIcon } from 'assets/icons/attach.svg';
import Uploadfile from 'components/ProfileSetting/UploadFile';
import AML from 'components/ProfileSetting/AML';
import Accountform from 'components/ProfileSetting/AccountForm';
import AMLModal from 'components/ProfileSetting/AMLModal';
import { useState } from 'react';
import KYC from 'components/ProfileSetting/KYC';

export default function ProfileSetting() {
  const [userType] = useState('not-confirmed');
  const [amlModal, setAMLModal] = useState(false);

  return (
    <div className="profile-page">
      <div className="profile-page__header">
        <div className="profile-page__title profile-page__header-item">
          <Title
            mainTitle="Profile Setting"
            badge="[001]"
          />
          <div className="profile-page__referral">
            <ReferralIcon />
            <div className="profile-page__referral-code">
              <span className="profile-page__referral-label">Referral Code:</span>
              <span className="profile-page__referral-value">458971162</span>
            </div>
          </div>
        </div>

        <div className="profile-page__aml profile-page__header-item">
          <AML userType={userType} onRequestAML={() => setAMLModal(true)} />
          <AMLModal
            modal={amlModal}
            setModal={setAMLModal}
          />
        </div>

        <div className="profile-page__wallet profile-page__header-item">
          <WalletAddress />
        </div>
      </div>

      <div className="profile-page__content">
        <div className="profile-page__left">
          <Uploadfile userType={userType} />
          <KYC userType={userType} />
        </div>

        <div className="profile-page__right">
          <Accountform />
        </div>
      </div>
    </div>
  )
}
