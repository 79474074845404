import Avatar from 'assets/images/avatar.png';
import './style.scss';

interface PropTypes {
  userType?: 'active' | 'confirmed' | 'not-confirmed';
  name: string; 
  avatar?: string;
  hideType?: boolean;
}

export default function Profile({ userType, name, avatar, hideType }: PropTypes) {
  return (
    <div className="profile">
      <div
        className={`profile__photo profile__photo--${userType}`}
      >
        <img
          src={avatar || Avatar}
          alt={name}
        />
      </div>
      <div
        className={`profile__info profile__info--${userType}`}
      >
        <div
          className={`profile__info-name profile__info-name--${userType}`}
        >
          {name}
        </div>
        {!hideType && userType && (
          <div
            className={
              `profile__info-type profile__info-type--${userType}`
            }
          >
            {userType}
          </div>
        )}
      </div>
    </div>
  );
}
