import './style.scss';
import logo from 'assets/images/logo.svg';

export default function Logo() {
  return (
    <div className="logo">
      <div className="logo__img">
        <img
          src={`${logo}`}
          alt=""
        />
      </div>
      <div className="logo__name">
        Digital <br /> Bank Labs
      </div>
    </div>
  );
}
