import './style.scss';

interface PropTypes {
  data: string[];
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  activeTab: number;
}
export default function TradeTabs({
  data,
  setActiveTab,
  activeTab,
}: PropTypes) {
  return (
    <div className="trade-tabs">
      {data.map((item, index) => (
        <div
          className={`tab-item ${activeTab === index + 1 ? 'active' : ''}`}
          key={index}
          onClick={() => setActiveTab(index + 1)}
        >
          {item}
        </div>
      ))}
    </div>
  );
}
