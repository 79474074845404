import './style.scss';
import Subtitle from 'components/UI/Subtitle';
import Title from 'components/UI/Title';
import WalletAddress from 'components/Shared/WalletAddress';
import { ReactComponent as AssetIcon } from 'assets/icons/allAssets.svg';
import PlanCard from 'components/Stake/PlanCard';
import Button from 'components/UI/Button';
import LockedSection from 'components/Stake/LockedSection';
import StakeModal from 'components/Stake/StakeModal';
import { useState } from 'react';

const cardsData = [
  {
    number: '01',
    min: '1000',
    payout: '4',
    apy: '22.25',
    locktime: '1',
  },
  {
    number: '02',
    min: '1000',
    payout: '8',
    apy: '28.25',
    locktime: '2',
  },
  {
    number: '03',
    min: '1000',
    payout: '12',
    apy: '32.25',
    locktime: '3',
  },
  {
    number: '04',
    min: '1000',
    payout: '24',
    apy: '34.25',
    locktime: '4',
  },
  {
    number: '05',
    min: '1000',
    payout: '32',
    apy: '40.25',
    locktime: '5',
  },
];

export default function Stake() {
  const [nextPage, setNextPage] = useState(false);
  const [selectedCard, setSelectedCard] = useState<number>();
  const [modal, setModal] = useState(false);

  return (
    <div className="stake">
      <StakeModal
        modal={modal}
        setModal={setModal}
      />
      <div className="stake__header">
        <div className="stake__header-left">
          <Subtitle
            subtitle="All Assets"
            icon={<AssetIcon />}
          />
          <Title
            mainTitle="Staking"
            badge="[001]"
          />
        </div>
        <WalletAddress />
      </div>
      <div className="stake__plans">
        <h3 className="stake__head">
          Select Plan
        </h3>
        <div className="stake__plans-wrapper">
          {cardsData?.map((item, index) => (
            <PlanCard
              onSelect={() => setSelectedCard(index)}
              key={index}
              onMore={() => setModal(true)}
              selected={selectedCard === index}
              data={item}
            />
          ))}
        </div>
      </div>

      {nextPage && (
        <div className="stake__form">
          <LockedSection />
        </div>
      )}

      <div className="stake__actions">
        <div />
        <Button
          title={!nextPage ? 'Next' : 'Stake Now'}
          variant="primary"
          badge='003'
          onClick={() => !nextPage && setNextPage(true)}
        />
      </div>
    </div>
  );
}
