import { Link, useNavigate } from 'react-router-dom';
import Button from 'components/UI/Button';
import Image from 'assets/images/image.png';
import Input from 'components/UI/Input';
import { ReactComponent as UserIcon } from 'assets/icons/user2.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/password.svg';

export default function Login() {
  const navigate = useNavigate();
  
  return (
    <div className="login-container">
      <div className="main-content__body">
        <div className="main-content__header">
          <div className="main-content__icon">
            <img
              className="main-content__icon-file"
              src={Image}
              alt="Authentication"
            />
          </div>
          <div className="main-content__title">Welcome Back!</div>
          <div className="main-content__text">
            Trusted by millions. Digital Bank Labs is a secure investing platform making the
            world of <span className='highlighted'>web3</span> accessible to all.
          </div>
        </div>
        <div className="main-content__form">
          <Input
            label="Username"
            icon={<UserIcon />}
            placeholder="Enter Your Username"
          />
          <Input
            label="Password"
            icon={<PasswordIcon />}
            placeholder="Enter Your Password"
            button="show"
            type="password"
          />

          <Link 
            to="/forgotpassword"
            className="main-content__link"
          >
            Forgot your Password?
          </Link>
        </div>
      </div>
      <div className="main-content__footer">
        <div className="main-content__footer-content">
          <span className="main-content__text text-normal">
            if you dont have an account
            <Link className="highlighted" to="/signup"> Sign up now</Link>
          </span>
        </div>
        <div
          className="main-content__button"
          onClick={() => navigate('/dashboard')}
        >
          <Button
            title="Login"
            badge="001"
            variant="primary"
          />
        </div>
      </div>
    </div>
  );
}
