import { useNavigate } from 'react-router-dom';
import './style.scss';
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg';

interface propTypes {
  variant?: 'primary' | 'secondary' | 'info' | 'warning';
  title?: string;
  badge?: string;
  arrow?: boolean;
  icon?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}
export default function Button({
  variant,
  title,
  badge,
  arrow,
  icon,
  className,
  onClick,
  ...rest
}: propTypes) {
  const navigate = useNavigate();
  return (
    <button
      className={
        'button ' +
        (variant === 'primary'
          ? 'btn-primary'
          : variant === 'secondary'
          ? 'btn-secondary'
          : variant === 'warning'
          ? 'btn-warning'
          : variant === 'info'
          ? 'btn-info'
          : 'btn-info') +
        ' ' +
        className
      }
      onClick={onClick}
      {...rest}
    >
      {icon && (<div className="button__icon">{icon}</div>)}
      <div className="button__title">{title}</div>
      {arrow && <Arrow />}
      <div className="button--badge">{badge}</div>
    </button>
  );
}
